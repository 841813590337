@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	//font-family: NeueMontreal, sans-serif; // TODO remove when global font is enabled in _app.tsx

	background-color: $white;
	color: $black;
	position: relative;

	min-height: 100vh;

	width: 100%;

	text-align: center;
}

.container article {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	min-height: 100svh;
}

.container h2 {
	@include eyebrow1;

	color: #a6a6a6;
	text-transform: uppercase;

	@include sm-down {
		@include eyebrow2;

		margin-bottom: 1.5rem;
	}

	margin-bottom: 1.5rem;
}

.container p {
	@include headline3;

	@include sm-down {
		@include headline5;
	}

	max-width: 26ch;

	margin-inline: auto;
}

.text_article {
	padding: 0 1rem;
}

.text_article p {
	margin-bottom: 2.5rem;

	&:last-of-type {
		opacity: 0.5;
	}
}

.video_article {
	background-color: $black;
	color: $white;
}

.simple_article {
	background-color: $black;
	color: $white;
	padding-bottom: $spacing-xl;

	@include sm-down {
		padding-bottom: $spacing-lg;
	}
}

.video_article p {
	&:first-of-type {
		opacity: 0.5;
		margin-top: 6rem;
	}

	&:last-of-type {
		@include sm-down {
			margin-bottom: 3rem;
		}
	}
}

.video {
	margin: 0 auto;

	width: 100%;
	max-width: 80rem;
}

.icons {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	column-gap: 90px;

	.icon_column {
		display: flex;
		flex-direction: column;
		text-align: center;
		row-gap: 20px;
		max-width: 285px;

		> .icon_title {
			@include headline6;
			opacity: 1;
			margin: 0;
		}

		> p {
			@include body3;
			opacity: 1;
			margin: 0;
		}
	}

	.icon {
		height: 48px;
		display: flex;
		justify-content: center;
		width: 100%;
	}

	@include sm-down {
		grid-template-columns: 1fr;
		column-gap: 0;
		row-gap: 15px;
		padding: 0 $spacing-sm;

		.icon_column {
			border-radius: 10px;
			background: #181818;
			max-width: 100%;
			padding: 30px;
		}
	}
}

.shop_cta {
	margin: 2rem;
}
