@import 'styles/mixins/media-queries';
@import 'styles/colors-2023';
@import 'styles/layout-tokens';
@import 'styles/mixins/fonts-2023';
@import 'styles/mixins/button-styles-2023';

.benefits {
	box-shadow: $neutral-5 0 0 0 1px;
	border-radius: 6px;
	padding: 1rem;

	h3 {
		@include subheading5;
		margin-bottom: 1rem;

		> span {
			margin-top: 4px;
			font-weight: 400;
			display: block;
		}
	}
}

.benefits_list {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	justify-content: flex-start;
	align-items: flex-start;

	list-style: none;
	margin: 0;
}

.benefits_list li {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	gap: 0.5rem;

	p {
		@include body6;
		color: $neutral-black;
	}
}

.benefit_icon {
	display: block;
	width: 1.5rem;
	height: 1.5rem;
	margin-bottom: 0.25rem;
}
