@import 'styles/mixins/fonts-2024';
@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';

.container {
	background-color: #141414;
	color: $white;

	padding: $spacing-xxl $spacing-lg $spacing-xl;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 6.25rem;
	@include sm-down {
		display: flow;
		padding: $spacing-lg $spacing-sm;
	}
}

.container2 {
	background-color: #141414;
	color: $white;

	padding: $spacing-xl $spacing-lg $spacing-lg;

	@include sm-down {
		padding: $spacing-md $spacing-sm;
	}
}

.awards_section {
	background-color: $white;
	color: $black;

	padding: $spacing-xl $spacing-xl;

	@include sm-down {
		padding: $spacing-lg $spacing-sm $spacing-sm;
	}

	.awards_wrapper > article {
		border-radius: 16px;
		background: var(--Off-White, #f2f2f2);
		backdrop-filter: blur(21.450000762939453px);
	}
}

.header {
	max-width: 84rem;
	margin-inline: auto;

	h2 {
		@include headline2;

		max-width: 16ch;

		@include sm-down {
			@include headline5;

			text-align: center;

			margin-inline: auto;
		}
	}

	margin-bottom: 3.5rem;
}

.header2 {
	max-width: 70ch;
	text-align: center;
	margin-inline: auto;
	margin-bottom: $spacing-xl;

	h2 {
		@include headline3;

		max-width: 20ch;
		margin-inline: auto;

		@include sm-down {
			@include headline5;
			text-align: center;
		}
	}

	@include sm-down {
		margin-bottom: $spacing-lg;
	}
}

.card_list {
	position: relative;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(2, 1fr);
	gap: 1rem;

	max-width: 82rem;
	margin-inline: auto;

	margin-bottom: 8rem;

	@include sm-down {
		display: flex;
		flex-direction: column;

		align-items: center;

		margin-bottom: 4rem;
	}

	list-style: none;
}

.card_columns {
	position: relative;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 20px;
	max-width: 1290px;
	margin-inline: auto;
	margin-bottom: $spacing-lg;

	@include sm-down {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 20px;
		margin-bottom: $spacing-md;
	}

	list-style: none;

	.card {
		display: flex;
		flex-direction: column;
		row-gap: 20px;

		.small_tag {
			border-radius: 4px;
			background: rgba(255, 255, 255, 0.25);
			font-size: 14px;
			margin: 0 15px;
			align-self: flex-start;
			padding: 0 10px;
		}

		p {
			padding: 0 15px;
		}

		img {
			border-radius: 7px;
		}

		> div {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			row-gap: 10px;
		}

		@include sm-down {
			position: relative;

			> div {
				position: absolute;
				bottom: 15px;
				left: 25px;
				row-gap: 5px;

				> p {
					padding: 0;
				}

				> .small_tag {
					margin: 0;
				}
			}
		}
	}
}

.full_shade {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	background: rgba(0, 0, 0, 1);
	z-index: -1;

	transition: all 0.5s ease-in-out;
}

.card_wrapper {
	max-width: 40rem;
	width: 100%;

	&:nth-child(1) {
		grid-column: 1 / 2;
		grid-row: 1 / 3;
	}

	&:nth-child(2) {
		grid-column: 2 / 3;
		grid-row: 1 / 2;
	}

	&:nth-child(3) {
		grid-column: 2 /3;
		grid-row: 2 / 3;
	}
}

.card_inner {
	position: relative;
	overflow: hidden;

	height: 100%;
	width: 100%;

	//cursor: pointer;

	@include sm-down {
		height: 400px;
	}
}

.card_inner {
	position: relative;
	overflow: hidden;
}

.image_wrapper {
	width: 100%;
	height: 100%;
	max-width: 40rem;
	border-radius: 0.5rem;
	overflow: hidden;

	position: relative;

	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: linear-gradient(207deg, rgba(0, 0, 0, 0) 47.82%, rgba(0, 0, 0, 0.42) 88.61%);
	}

	img {
		width: 100%;
		height: 100%;
	}
}

.image_shade {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	background: rgba(0, 0, 0, 0.2);
}

.modal_image_wrapper {
	border-radius: 0.5rem;
}

.modal_open_button {
	position: absolute;
	top: 0.5rem;
	right: 0.5rem;

	z-index: 1;

	background: rgba(255, 255, 255, 0.25);

	backdrop-filter: blur(8px);
	border-radius: 0.5rem;

	padding: 0.75rem;
}

.modal_close_button {
	position: fixed;
	top: 1.5rem;
	right: 1.5rem;

	z-index: 1;

	background: rgba(255, 255, 255, 0.08);
	backdrop-filter: blur(8px);
	border-radius: 0.5rem;

	padding: 0.75rem;

	&:hover {
		cursor: pointer;
	}
}

.blurb_wrapper {
	position: absolute;

	left: 2rem;
	bottom: 1.5rem;

	z-index: 1;
}

.tag {
	display: inline-flex;
	justify-content: center;
	align-items: center;

	@include eyebrow2;

	color: $white;
}

.card_tag {
	padding: 0.25rem 0.5rem;

	border-radius: 0.25rem;
	background: rgba(255, 255, 255, 0.25);

	@include sm-down {
		padding: 0.25rem 0.5rem;
	}

	margin-bottom: 1rem;

	backdrop-filter: blur(2px);
}

.modal_tag {
	padding: 0.25rem 0.75rem;
	color: #a6a6a6;
	border: thin solid #a6a6a6;
	border-radius: 0.5rem;
}

.blurb {
	max-width: 20.7ch;

	@include headline5;

	@include sm-down {
		@include headline6;

		font-weight: 400; // TODO not in spec
	}
}

.hidden_header_inner {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2rem;
	margin-bottom: 5rem;

	text-align: center;

	@include sm-down {
		margin-bottom: 4.5rem;
	}

	h3 {
		// TODO tokenize

		font-size: 3rem;
		line-height: 3.3rem;
		letter-spacing: -0.03rem;
		font-weight: 400;

		margin: 0;

		@include sm-down {
			// TODO tokenize

			font-size: 2rem;
			line-height: 2.6rem;
			letter-spacing: normal;
		}
	}
}

.modal_content {
	margin-top: 5rem;

	@include sm-down {
		margin-top: 3rem;
	}

	ul {
		padding: 0;
		margin: 0;

		list-style: none;

		display: grid;
		grid-template-columns: repeat(4, minmax(0, 1fr));

		gap: 2.6875rem;

		@include sm-down {
			grid-template-columns: 1fr;
			gap: 1.5rem;
		}
	}
}

.content_body {
	// TODO tokenize

	font-size: 1.5rem;
	line-height: 1.95rem;
	font-weight: 400;

	margin-bottom: 7.5rem;

	max-width: 43ch;

	@include sm-down {
		// TODO tokenize

		font-size: 1.125rem;
		line-height: 1.575rem;

		margin-bottom: 3rem;

		max-width: 40ch;
	}
}

.content_image_container {
	max-width: 66%;

	margin-bottom: 6.25rem;

	border-radius: 0.25rem;
	overflow: hidden;

	@include sm-down {
		max-width: 100%;
		margin: 0 3rem 3rem;
	}
}

.big_stat {
	list-style: none;
	padding: 1.5rem;
	padding-bottom: 2rem;
	height: 100%;

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 3.75rem;

	border-radius: 0.9375rem;
	background: #1b1b1b;
	box-shadow: 0px 23px 51px 0px rgba(0, 0, 0, 0.05), 0px 93px 93px 0px rgba(0, 0, 0, 0.05), 0px 209px 125px 0px rgba(0, 0, 0, 0.03), 0px 371px 148px 0px rgba(0, 0, 0, 0.01),
		0px 579px 162px 0px rgba(0, 0, 0, 0);

	@include sm-down {
		gap: 2rem;
	}
}

.big_number {
	white-space: nowrap;
	margin: 0;
	// TODO tokenize

	font-size: 6.25rem;
	line-height: 1.1;
	letter-spacing: -0.0625rem;
	font-weight: 500;
}

.small_unit,
.eyebrow {
	color: #6a6a6a;
	text-transform: uppercase;

	margin-left: 1rem;
	max-width: 8ch;

	display: inline-block;
}

.small_unit {
	// TODO tokenize

	font-size: 2rem;
	line-height: 2.6rem;
	font-weight: 400;
}

.caption {
	// TODO tokenize

	font-size: 1.125rem;
	line-height: 1.575rem;
	font-weight: 400;

	margin: 0;
}

.eyebrow {
	// TODO tokenize
	font-size: 1rem;
	line-height: 1.4rem;
	letter-spacing: 0.0625rem;
	font-weight: 500;

	white-space: normal;
}

.awards_section_title {
	max-width: 82rem;

	@include headline4;
	margin: 0 auto 3.5rem;

	@include sm-down {
		@include headline5;
		margin: 0 auto 3rem;
	}
}

.awards_list {
	list-style: none;
	padding: 0;
	margin: 0;

	display: grid;
	max-width: 82rem;

	margin-inline: auto;
	grid-template-columns: repeat(4, minmax(0, 1fr));
	justify-content: center;

	gap: 1.5rem;

	@include sm-down {
		display: flex;
		gap: 2rem;

		justify-content: flex-start;

		> li {
			flex: 1 0 0;
			min-width: 66vw;
		}
	}

	overflow: auto;
}

.award_container {
	padding: 2rem;

	border-radius: 0.5rem;
	background: rgba(255, 255, 255, 0.03);

	height: 100%;

	display: flex;

	flex-direction: column;
}

.award_text {
	flex: 1;
	color: $white;
}

.award_year {
	@include body3;

	color: #a6a6a6;
	margin-bottom: 1.5rem;
}

.award_title {
	@include headline8;
	margin-bottom: 4rem;
}

.award_logo {
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: flex-start;

	max-height: 2rem;
	width: 12rem;

	max-width: 100%;

	svg {
		height: 100%;
	}
}

.modal {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 8.75rem 7.87rem;

	visibility: hidden;

	border-radius: 1rem;
	background-color: $black;

	isolation: isolate;

	z-index: 9999;

	overflow: auto;
	overscroll-behavior: contain;

	transition: all 0.5s ease-in-out;

	@include sm-down {
		padding: 5.31rem 1.31rem 1.19rem;
	}
}

.cta_container {
	margin-top: 2rem;

	display: inline-flex;
	flex-direction: column;

	@include md-up {
		flex-direction: row;
	}

	gap: 1.5rem;

	.arrow_cta {
		@include button2;
	}
}

.slideout_cta_container {
	@include sm-down {
		text-align: center;
		margin-top: 2rem;
	}
}
