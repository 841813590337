@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2023';
@import 'styles/colors-2023';
@import 'styles/mixins/button-styles-2023';

.container {
	position: relative;

	.blocker {
		position: absolute;
		inset: 0;
		background: rgba(255, 255, 255, 0.5);
		z-index: 1;
		cursor: not-allowed;
	}
}

.methods {
	display: grid;
	border: none;
	padding: 0;
	margin: 0;
	grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
	gap: 0.5rem;

	&[data-num-methods='4'] {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}

	@include sm-down {
		width: 100%;
		display: flex;
		flex-direction: column;
		max-width: unset;
	}
}

$--focusBoxShadow: 0 0 0 3px hsla(0, 0%, 0%, 0.25), 0 1px 1px 0 rgba(0, 0, 0, 0.08);

.radio_label {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;

	box-shadow: inset 0 0 0 1px #d9d9d9;
	padding: 0.75rem;
	border-radius: 5px;

	@include body7;

	svg {
		height: 0.75em;
		&:global(.affirm) {
			height: 1.1em;
		}
	}

	cursor: pointer;

	&:focus-visible {
		outline: 0;
		border-color: hsla(0, 0%, 0%, 50%);
		box-shadow: 0 1px 1px rgba(0, 0, 0, 0.03), 0 3px 6px rgba(0, 0, 0, 0.02), $--focusBoxShadow;
	}
}

.radio_label_checked {
	box-shadow: inset 0 0 0 1px $neutral-black;
}

.bubble {
	border-radius: 9999px;
	width: 1.25rem;
	height: 1.25rem;
	display: inline-block;

	box-shadow: inset 0 0 0 1px #d9d9d9;

	margin-right: 0.5rem;

	transition: all 0.25s ease-in-out;
}

.bubble_filled {
	box-shadow: inset 0 0 0 0.4rem $neutral-black;
}

.express_checkout {
	margin: 0 0 10px !important;
}

.divider {
	margin: 1rem 0 !important;

	width: 100%;
	display: grid;
	position: relative;
	grid-template-areas: 'center';
	justify-content: center;
	align-items: center;

	isolation: isolate;

	> div {
		position: absolute;
		width: 100%;
		left: 0;
		right: 0;
		height: 1px;
		background: #eaeaea;
		z-index: -1;
	}

	> p {
		grid-area: center;
		@include body6;
		color: #a0a0a0;

		background: $neutral-white;
		padding: 0 0.5rem;
	}
}

.truemed_logo {
	display: flex;
	align-items: center;
	column-gap: 5px;
}
