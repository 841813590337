@import '../../styles/mixins/media-queries';
@import '../../styles/mixins/fonts-2023';
@import '../../styles/colors-2023';

.container {
	background-color: $neutral-white;
	color: $neutral-black;

	max-width: 1200px;
	margin: auto;

	padding: 1rem;

	@include md-up {
		padding: 2rem;
	}
}

.fairing_container {
	position: fixed;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	container-type: size;
}

.header {
	display: flex;
	justify-content: center;
	align-items: center;

	svg {
		width: 4rem;
		height: 1.75rem;
	}

	margin-bottom: 1rem;

	@include md-up {
		svg {
			width: 6rem;
			height: 3rem;
		}

		margin-bottom: 2rem;
	}
}

.checkout_grid {
	display: grid;
	row-gap: 2rem;

	@include md-up {
		grid-template-columns: repeat(2, minmax(0, 1fr));
		column-gap: 6rem;
		row-gap: unset;
	}
}

.checkout_selections {
	grid-row: 2;

	> div {
		&:not(:last-child) {
			margin-bottom: 2rem;
		}
	}

	@include md-up {
		grid-row: 1 / span 8; // done so that the payment button is always flush to the order summary
		> div {
			margin-bottom: 4rem;
		}
	}
}

.confirmation {
	grid-row: 2;

	> div {
		&:not(:last-child) {
			margin-bottom: 2rem;
		}
	}

	@include md-up {
		grid-row: 1 / span 2;
		> div {
			margin-bottom: 4rem;
		}
	}
}

.order_summary {
	grid-row: 1;
	position: relative;

	@include md-up {
		grid-row: unset;
	}

	.order_spinner {
		min-height: 20rem;
		display: flex;
		align-items: center;
	}
}

.section_header {
	@include subheading4;
	color: #333333;
	margin-bottom: 1.25rem;
}

.text_input {
	border: none;
	border-radius: 6px;
	box-shadow: #d9d9d9 0 0 0 1px;
	width: 100%;

	@include body6;
	padding: 0.75rem;
	color: $neutral-black;

	&::placeholder {
		font: inherit;
		color: #adb3c5;
	}
}

$--focusBoxShadow: 0 0 0 3px hsla(0, 0%, 0%, 0.25), 0 1px 1px 0 rgba(0, 0, 0, 0.08);
$--focusBoxShadowError: 0 0 0 3px hsla(0, 96%, 45%, 0.25), 0 1px 1px 0 rgba(0, 0, 0, 0.08);

.text_input {
	border: none;
	border-radius: 6px;
	box-shadow: #d9d9d9 0 0 0 1px;
	width: 100%;

	@include body5;
	padding: 0.75rem;
	color: $neutral-black;

	&::placeholder {
		font: inherit;
		color: #adb3c5;
	}

	&:focus {
		outline: 0;
		border-color: hsla(0, 0%, 0%, 50%);
		box-shadow: 0 1px 1px rgba(0, 0, 0, 0.03), 0 3px 6px rgba(0, 0, 0, 0.02), $--focusBoxShadow;
	}

	transition: background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease;
}

.fancy_label {
	position: absolute;
	pointer-events: none;
	transform-origin: top left;
	z-index: 2;
	will-change: transform;

	@include body6;

	margin-left: 0.75rem;

	transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}

.fancy_text_input.fancy_text_input {
	border-radius: 6px;
	border: 1px solid #d9d9d9;
	width: 100%;

	@include body5;
	padding-block: 0;
	padding-inline: 0;
	color: $neutral-black;

	padding: 2rem 0.75rem 0.75rem;

	&::placeholder {
		font: inherit;
		color: #adb3c5;
		opacity: 0;
		transition: opacity 0.08s ease;
	}

	&:focus {
		outline: 0;
		border-color: hsla(210, 96%, 45%, 50%);
		box-shadow: 0 1px 1px rgba(0, 0, 0, 0.03), 0 3px 6px rgba(0, 0, 0, 0.02), $--focusBoxShadow;
	}

	transition: background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease;
}

.fancy_text_input_focused {
	&::placeholder {
		opacity: 1;
	}
}

.text_error.text_error {
	border: 2px solid #df1b41;

	&:focus {
		border-color: hsla(348, 78%, 49%, 50%);
		box-shadow: 0 1px 1px rgba(0, 0, 0, 0.03), 0 3px 6px rgba(0, 0, 0, 0.02), $--focusBoxShadowError;
	}
}

.checkbox_container {
	margin: 0.75rem auto;
	display: flex;

	p {
		@include body6;
		color: #797c8e;
	}
}

.checkbox {
	width: 1.125rem;
	height: 1.125rem;
	border-radius: 3px;
	box-shadow: inset #d9d9d9 0 0 0 1px;
	margin-right: 0.5rem;
	cursor: pointer;

	display: flex;
	justify-content: center;
	align-items: center;

	padding: 0.2rem;

	transition: all 0.1s ease-in-out;

	.checkbox_check {
		width: 100%;
		height: 100%;
	}
}

.checkbox_selected {
	box-shadow: inset $neutral-black 0 0 0 1rem;
}

.s_eight_logo {
	width: 120px;
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 60px;
}

#card-errors {
	color: red;
}

.s_container {
	max-width: 920px;
	display: block;
	margin-left: auto;
	margin-right: auto;
	padding-top: 60px;
	padding-bottom: 60px;
	text-align: center;
	padding-left: 24px;
	padding-right: 24px;
}

.s_card_list {
	list-style-type: none;
	margin: 0;
	padding: 0;
	display: flex;
	justify-content: flex-end;
	gap: 10px;
}

.s_payment_heading {
	display: flex;
	width: 100%;
	justify-content: space-between;
}

.s_header_heading {
	margin-block-start: 0;
	margin-block-end: 0;
	margin-bottom: 16px;
}

.s_container .s_text {
	display: block;
	margin-left: auto;
	margin-right: auto;
	font-size: 17px;
	line-height: 1.4;
	margin-bottom: 32px;
	color: #7a7c8d;
	max-width: 550px;
	text-align: left;

	@include sm-down {
		font-size: 14px;
	}
}

.s_container .s_heading_2 {
	line-height: 130%;
	color: #000;
	margin-bottom: 30px;
	display: block;
	margin-left: auto;
	margin-right: auto;
	font-size: 32px;
	font-weight: 700;

	@include sm-down {
		font-size: 22px;

		br {
			display: none;
		}
	}
}

.s_terms {
	color: #7a7c8d;
	line-height: 1.4;
	max-width: 100ch;
	font-size: 12px;
	margin: 0 auto;

	@include sm-down {
		text-align: center;
	}
}

@include sm-up {
	#card-element {
		padding: 16px;
	}
	.s_payment_wrapper {
		border: none;
		padding: 0;
		max-width: 680px;
	}
	.s_bottom_wrapper {
		margin-top: 24px;
		max-width: 550px;
		margin: 0 auto;
	}
	.s_help_1 {
		display: none;
	}

	.s_container .s_heading_1 {
		font-size: 14px;
		margin-bottom: 16px;
	}
	.s_container .s_text {
		font-size: 14px;
	}

	#s_pay {
		height: 44px;
		width: 100%;
	}
	#card-errors {
		margin-top: 24px;
	}

	.RCT-banner.banner {
		display: none !important;
	}
}

.stripe-modal-content {
	background-color: #fefefe;
	margin: auto;
	padding: 30px 60px;
	border: 1px solid #888;
	width: 80%;
	border-radius: 6px;
	max-width: 400px;
}

.stripe-modal-content p {
	font-size: 14px;
	margin-bottom: 30px;
}

.stripe_modal_heading {
	font-size: 30px;
}

@include max(600px) {
	.stripe_modal_content {
		padding: 20px;
		width: 80%;
	}

	.stripe_modal_content p {
		font-size: 14px;
		margin-bottom: 20px;
	}
}

.s_help_2 {
	font-size: 12px;
	font-weight: 700;
	margin: 0;
}

.s_terms_w_icon {
	display: flex;
	align-items: center;
	margin-top: 30px;
	justify-content: center;
	font-size: 12px;
	font-weight: 700;
}

.s_payment_wrapper {
	max-width: 550px;
	display: flex;
	flex-direction: column;
	gap: 2rem;
	margin-left: auto;
	margin-right: auto;
}

.cta {
	max-width: 550px;
	margin: 30px auto 0 auto;
	display: flex;
	justify-content: space-between;
	height: 50px;
	align-items: center;

	@include sm-down {
		display: flex;
		flex-direction: column-reverse;
		margin-top: 20px;
		height: auto;
		row-gap: 20px;

		button {
			width: 100%;
		}
	}
}

.terms {
	display: block;
	text-align: left;

	> div {
		display: inline-block;
	}

	@include sm-down {
		text-align: center;
	}
}

.loading {
	opacity: 0.3;
	pointer-events: none;
}

.benefits_container {
	margin-top: 2rem;
}

.hide_mobile {
	@include sm-down {
		display: none;
	}
}

.error_message {
	margin-top: 0.25rem;
	color: #df1b41;
	font-size: 0.93rem; //taken from stripe
}

.stripe_modal_trigger {
	:global(.modal_container) > div {
		width: 100%;
		max-width: 600px;

		.stripe_modal_content {
			padding: 60px;

			@include sm-down {
				padding: 30px;
			}
		}

		.stripe_modal_content :is(p, h2) {
			text-align: left;
			display: block;
			width: 100%;
		}

		.stripe_modal_content h2 {
			border-bottom: 1px solid #d9d9d9;
			padding-bottom: 10px;
		}
	}
}

.gift_section {
	border: 1px solid #d9d9d9;
	border-radius: 5px;
	padding: 16px;
	display: flex;
	gap: 8px;

	label {
		margin: 0;
		margin-top: 4px;
	}

	fieldset {
		border: none;
		margin: 0;
		padding: 0;
		> label {
			display: inline-block;
			&:nth-child(2) {
				margin-right: 16px;
			}
			p {
				display: inline;
				position: relative;
			}
		}

		input[type='radio'] {
			display: none;
		}

		input[type='radio'] + p > span {
			content: ' ';
			position: relative;
			top: 5px;
			border-radius: 9999px;
			width: 1.25rem;
			height: 1.25rem;
			display: inline-block;
			box-shadow: inset 0 0 0 1px #d9d9d9;
			margin-right: 3px;
			transition: all 0.25s ease-in-out;
		}

		input[type='radio']:checked + p > span {
			box-shadow: inset 0 0 0 0.4rem #000;
		}
	}
}

.gift_description {
	margin-bottom: 1rem;
	color: #636363;
}

.radiotext {
	color: #636363;
}

.gift_cta {
	cursor: pointer;
	margin-top: 4px;
	margin-bottom: 10px;
}
