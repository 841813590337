@import 'styles/mixins/utilities';
@import 'styles/constants';
@import 'styles/colors';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/theme';

.trigger_p {
	text-decoration: none;
	color: $type-blue3 !important;
	cursor: pointer;
	text-decoration: underline;
	@include xs-only {
		font-size: 12px;
	}
}

.financing {
	font-weight: 400;
	font-size: 14px;

	svg {
		max-height: 1.2em;
		margin: 0 5px;
		max-width: 4em;
	}

	.AffirmLineBF {
		display: none;
	}
}

.accordion {
	:global(.accordion-panel) {
		border-color: rgb(0 0 0 / 10%);
	}
}

.price_section_tag_container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 0;
}

.size_error {
	outline: 1px solid red;
	outline-offset: 10px;
	position: relative;

	.error_message {
		color: red;
		position: absolute;
		top: 0;
		right: 0;
		display: inline-block;
		right: 5px;
		display: inline-block;
		font-size: 12px;
	}
}

.error_message {
	display: none;
}

.hide_membership {
	@include visually-hidden;
}
