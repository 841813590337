@mixin pod-3-text {
	font-family: var(--neue-montreal), sans-serif;
	margin-top: 0;
	margin-bottom: 0;
}

@mixin pod-3-header {
	@include pod-3-text;
	font-weight: 700;
}

@mixin pod-3-body {
	@include pod-3-text;
	font-weight: 400;
	line-height: 140%;
	letter-spacing: 0;
}

@mixin pod-3-header-1 {
	@include pod-3-header;
	font-size: 80px;
	line-height: 130%;
	letter-spacing: 0.5px;
}

@mixin pod-3-header-2 {
	@include pod-3-header;
	font-size: 48px;
	line-height: 130%;
	letter-spacing: 0.5px;
}

@mixin pod-3-header-3 {
	@include pod-3-header;
	font-size: 40px;
	line-height: 140%;
	letter-spacing: 0.5px;
}

@mixin pod-3-header-4 {
	@include pod-3-header;
	font-size: 32px;
	line-height: 130%;
	letter-spacing: 0;
}

@mixin pod-3-header-5 {
	@include pod-3-header;
	font-size: 24px;
	line-height: 140%;
	letter-spacing: 0;
}

@mixin pod-3-header-6 {
	@include pod-3-header;
	font-size: 20px;
	line-height: 140%;
	letter-spacing: 0;
}

@mixin pod-3-header-7 {
	@include pod-3-header;
	font-size: 18px;
	line-height: 140%;
	letter-spacing: 0;
}

@mixin pod-3-header-8 {
	@include pod-3-header;
	font-size: 16px;
	line-height: 22px;
	letter-spacing: 0;
}

@mixin pod-3-header-9-14 {
	@include pod-3-header;
	font-size: 14px;
	line-height: 22px;
	letter-spacing: 0;
}

@mixin pod-3-header-9-12 {
	@include pod-3-header;
	font-size: 12px;
	line-height: 140%;
	letter-spacing: 0.24px;
}

@mixin pod-3-body-1 {
	@include pod-3-body;
	font-size: 24px;
}

@mixin pod-3-body-2 {
	@include pod-3-body;
	font-size: 20px;
}

@mixin pod-3-body-3 {
	@include pod-3-body;
	font-size: 18px;
}

@mixin pod-3-body-4 {
	@include pod-3-body;
	font-size: 16px;
}

@mixin pod-3-body-5 {
	@include pod-3-body;
	font-size: 12px;
}
