@import 'styles/constants';
@import 'styles/colors';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/utilities';
@import 'styles/mixins/fonts';
@import 'styles/mixins/pod-3-fonts';
@import 'styles/mixins/fonts-2023';
@import 'styles/mixins/fonts-2024';

.pricing {
	.alternate_pricing {
		@include tag1;

		color: #a0a0a0;

		svg {
			width: auto;
			height: 1em;
			margin: 0 0.5ch;
		}

		&:last-of-type {
			margin-bottom: 0.75rem;
		}
	}
}

.shop {
	--vertical-spacing-margin: 25px;
	color: #000;
	display: flex;
	flex-direction: column;
	gap: 32px;

	position: relative;

	.atc {
		grid-area: atc;

		> div {
			margin: auto;
			@include md-up {
				max-width: 520px;
			}
		}

		.product_info :global(.paragraph):last-of-type {
			margin-bottom: 0;
		}
	}

	@include sm-down {
		.carousel {
			:global(.slick-slider) {
				margin: 0;
			}

			aspect-ratio: 390/266;
			width: calc(100% + 48px);
			margin-left: -24px;
			margin-right: -24px;
		}
	}

	@include md-up {
		display: grid;
		grid-template-areas: 'carousel atc' 'features atc';
		grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);

		.features {
			grid-area: features;
			align-self: end; // This is annoying in terms of styling but impossible to avoid with using grid.
		}

		.carousel {
			grid-area: carousel;

			// overflow: hidden;
			aspect-ratio: 615 / 417;

			&.sticky {
				position: sticky;
				top: 98px;
			}
		}
	}

	@media (min-width: 1800px) {
		.carousel {
			aspect-ratio: 615 / 399;
		}
	}
}

.shop_carousel {
	width: 100%;

	@include sm-down {
		position: relative;
		top: 0;
		// Offset padding
		left: -38px;
		width: 100vw;

		border-radius: 0 !important;
	}

	@include xs-only {
		// Offset padding
		left: -24px;
	}
}

.custom_feature {
	margin-top: 40px;
	margin-bottom: 40px;
	font-weight: 700;

	> strong {
		font-size: 18px;
		display: block;
		margin-bottom: 10px;
	}
}

.strikethrough {
	margin-left: 10px;
	text-decoration: line-through;
	opacity: 0.5;
}

.carousel {
	position: relative;
	@include sm-only {
		margin-bottom: 50px;
	}

	@include xs-only {
		margin-bottom: 25px;
	}
}

.sticky_carousel.sticky_carousel {
	@include md-up {
		grid-area: unset;
		overflow: hidden;
		position: sticky;
		top: 150px;
		max-width: 100%;
	}
}

.right_side_normal {
	margin: var(--vertical-spacing-margin) auto 0 auto;
	display: flex;
	flex-direction: column;
	row-gap: 40px;
}

.shop_spf {
	@include md-up {
		grid-template-areas: 'carousel atc' 'carousel atc';
	}
}

.atc_button.atc_button {
	width: 100%;

	@include md-up {
		width: 100% !important;
	}

	&:global(.disabled) {
		cursor: not-allowed;
	}
}

.shop_pay_button.shop_pay_button {
	width: 100%;

	img {
		height: 20px;
		width: auto;
		margin-left: -4px;
	}

	background-color: rgb(91 49 245 / 10%);
	border-color: #5a31f4;

	&:hover {
		background-color: rgb(71 38 191 / 30%);
		border-color: #4726bf;
		color: #5a31f4;
	}

	&:disabled {
		background-color: #957af5;
		border-color: #957af5;
		color: rgb(255 255 255 / 50%);

		img {
			filter: brightness(100);
			opacity: 0.5;
		}
	}

	color: #5a31f4;

	@include md-up {
		width: 100% !important;
	}

	&:global(.disabled) {
		cursor: not-allowed;
	}
}

.shop_pay_logo {
	margin-left: 0.5ch;
}

.atc_button_secondary {
	width: 100%;
	display: block;

	background-color: white;
	color: $brand-primary;

	@include md-up {
		width: 100% !important;
		display: block !important;
	}

	&:global(.disabled) {
		cursor: not-allowed;
	}

	> span {
		width: 18px;
		height: 18px;
		top: calc(50% - 9px);
	}
}

.product_type_select {
	@include subheading3;
	display: flex;
	align-items: center;
	margin-bottom: 10px;

	&.product_type_select_alt {
		display: flex;
		align-items: center;
		column-gap: 10px;
	}

	.sa_vings_tag {
		margin-left: auto;
		font-size: 0.6em;
	}
}

.membership_num_accounts {
	margin-bottom: 10px;
	font-size: 14px;
}

.membership_fieldset {
	> label {
		justify-content: flex-start;

		:global(.title) {
			@include md-up {
				min-height: 42px;
			}
		}
	}
}

.price_section_tag_container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
}

.podsaletag {
	color: $brand-secondary;
	margin-left: auto;
	display: flex;

	p {
		font-size: 14px;
		line-height: 20px;
		margin-bottom: 0;

		span {
			@include xs-only {
				display: none;
			}
		}
	}

	img {
		width: 16px;
		height: 16px;
		margin-right: 4px;
		position: relative;
		top: 1px;
	}
}

.pillow_size_select {
	margin-bottom: 10px;

	label {
		position: relative;
		height: 40px;

		input {
			@include visually-hidden;
		}

		input:focus + span,
		input:hover + span {
			outline: 1px solid $brand-primary;
		}

		span {
			display: flex;
			align-items: center;
			padding: 4px;
			font-weight: 500;
			white-space: nowrap;
		}
	}

	fieldset {
		border: none;
		padding: 0;
		margin: 0;
		display: flex;
		max-width: 200px;
		justify-content: space-between;
	}

	legend {
		font-weight: bold;
		margin-bottom: 15px;
	}

	&:not(:global(.legend-visible)) legend {
		@include visually-hidden;
	}

	span::before {
		display: inline-block;
		content: ' ';
		border-radius: 50%;
		border: 2px solid gray;
		margin-right: 10px;
		width: 24px;
		height: 24px;
	}

	span::after {
		display: inline-block;
		content: ' ';
		border-radius: 50%;
		position: absolute;
		width: 18px;
		height: 18px;
		left: 7px;
		top: 7px;
	}

	input:checked + span::before {
		border: 2px solid $brand-primary;
	}

	input:checked + span::after {
		background-color: $brand-primary;
	}

	&.colors {
		margin-bottom: 30px;

		fieldset {
			max-width: 230px;
		}

		span {
			cursor: pointer;
			position: relative;
		}

		span::before {
			margin: 0;
			width: 35px;
			height: 35px;
			border-color: transparent;
		}

		input:checked + span::after {
			background-color: currentcolor;
			outline: none;
		}

		span::after {
			outline: 1px solid #a5a3a3;
			background-color: currentcolor;
			left: 0;
			right: 0;
			margin: auto;
			top: 50%;
			transform: translateY(-50%);
			width: 24px;
			height: 24px;
		}
	}
}

.title {
	margin-bottom: 0.75rem !important;

	display: flex;
	align-items: center;
}

.subtitle {
	@include body6;
	margin: 1rem auto;
}

.product_description_label {
	font-size: 16px;
	margin-top: 50px;
	margin-bottom: 18px;
	border-bottom: 1px solid rgb(0 0 0 / 10%);
	padding-bottom: 18px;
}

.tonal_check {
	display: inline-block !important;
}

.tonal_modal {
	display: flex;
	line-height: 1.4;
	align-items: flex-end;

	// ugly selector to prevent modal 360px width. We need to redo this
	> div > div > div {
		width: auto;
	}
}

.trigger {
	text-decoration: underline;
	background: none;
	border: none;
	font-size: 18px;
	font-weight: bold;
	display: inherit;

	margin: 0;
	padding: 1px 6px;
	color: black;

	&:hover {
		cursor: pointer;
	}
}

.content.content {
	background-color: #fff;
	max-width: 900px;
	padding: 90px;
	position: relative;

	@include xs-only {
		padding: 60px 25px;
	}

	grid-template-columns: 1fr;
	grid-template-rows: auto auto;
	text-align: center;
	align-items: center;

	border-radius: 5px;
}

.modal_h.modal_h {
	margin-bottom: 15px;
}

.modal_checks.modal_checks {
	display: flex;
	justify-content: space-around;
	max-width: 630px;
	flex-direction: column;
	gap: 20px;

	@include sm-up {
		flex-direction: row;
	}
}

.modal_p.modal_p {
	font-weight: bold;
	font-size: 16px;
	white-space: nowrap;

	@include sm-down {
		white-space: unset;
	}
}

.modal_subtext.modal_subtext {
	color: rgb(0 0 0 / 70%);
	font-weight: normal;
	font-size: 14px;
	max-width: 630px;

	@include xs-only {
		font-size: 12px;
	}
}

.membership_checkbox_top_container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 10px;

	div {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	margin-bottom: 18px;
}

.membership_inner {
	background: #f7f8fa;
	padding: 24px 20px;
	border-radius: 5px;
}

.pseudo_mandatory_title_container {
	margin-bottom: 12px;
	display: flex;
	justify-content: space-between;
}

.pseudomembership_title {
	margin: 0;
}

.pseudomembership_subtitle {
	color: $brand-primary;
	font-weight: 700;
	margin: 0;
}

.membership_checkbox_pricing {
	p {
		margin-bottom: 0;
		font-size: 16px;

		@include sm-down {
			font-size: 14px;
		}
	}
}

.membership_checkbox {
	width: 20px;
	height: 20px;
}

.membership_strikethrough {
	color: #555b6c;
	opacity: 0.7;
	text-decoration: line-through;
}

.membership_actual {
	font-weight: bold;
	margin-left: 1ch;
}

.membership_benefits_title {
	font-weight: bold;
	font-size: 14px;
	margin-bottom: 0;

	@include sm-down {
		font-size: 12px;
	}
}

.membership_benefits {
	li {
		margin-left: 20px;
		margin-top: 8px;
		font-weight: 300;
		font-size: 14px;

		@include sm-down {
			font-size: 12px;
		}
	}
}

.tooltip_icon {
	position: relative;
	left: 5px;
	vertical-align: middle;

	img {
		width: 18px;
		height: 18px;
	}
}

.tooltip_container {
	padding: 100px;
}

.membership_check_variant_description {
	display: block;
	@include sm-down {
		display: none;
	}
}

.read_more {
	@include sm-up {
		display: none;
	}

	cursor: pointer;
	background: none;
	border: none;
	color: $type-blue3;
	text-transform: none;
	text-decoration: underline;
	@include pod-3-body-4;
	padding: 0;
	margin: 0;
	margin-top: 4px;
	height: unset;

	&:hover {
		cursor: pointer;
		background: none;
		border: none;
		color: $type-blue3;
		text-transform: none;
		text-decoration: underline;
	}
}

.region_tabs {
	margin-bottom: 24px;
}

.loading_placeholder {
	width: 100%;
	display: block;

	@include xs-only {
		width: 100vw;
		position: relative;
		left: -24px;
	}
}

.loading_placeholder_new {
	position: absolute;
	opacity: 1;
	inset: 0;

	width: 100%;
	height: 100%;
	z-index: 90;

	&.mounted {
		position: absolute;
	}

	> img {
		object-fit: cover !important;
		object-position: center center;
		height: 100%;
	}

	&.remove {
		transition: all 1000ms ease-in-out;
		opacity: 0;
		pointer-events: none;
	}
}

.spf_features {
	display: flex;
	justify-content: space-around;
	margin-top: 30px;
	margin-bottom: 0;

	li {
		display: flex;
		flex-direction: column;
		align-items: center;

		img {
			width: 40px;
			height: 40px;
			margin-bottom: 6px;
		}

		@include p-reg-desktop;
	}

	@include xs-only {
		display: none;
	}
}

.sticky_atc_hidden {
	transform: translateY(100%);
	transition: all 0.25s ease;

	height: 94px;
	position: fixed;
	width: 100%;
	bottom: 0;
	left: 0;
	right: 0;

	z-index: 10;

	@include md-up {
		display: none;
	}
}

.sticky_atc_show {
	transform: translateY(0);
}

.atc_container {
	display: flex;
	flex-direction: column;
	gap: 18px;
}

.atc_container_sticky_shown {
	@include sm-down {
		display: none;
	}
}

.note {
	background: #f7f8fa;
	border: 1px solid rgba(0 0 0 / 10%);
	border-radius: 3px;
	margin-bottom: 15px;
	padding: 12px;
}

.addmembership {
	padding: 20px;
	background-color: $ui-light-grey;
}

.titlebadge {
	font-weight: 700;
	top: -10px;
	position: relative;
	left: 8px;
	@include sm-down {
		top: -5px;
	}
}

.money_amount_tags {
	margin-bottom: 1.25rem !important;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 0.3rem;
}

.money_amount_tags_financing {
	margin-top: -10px;
	margin-bottom: 0;
}

.price_tag_compare {
	color: #b3b3b3;
	text-decoration: line-through;
}

.sa_vings_tag_container {
	position: relative;
	margin-left: 16px;
	padding: 6px 16px;

	background-color: #f2f5ff;
	border-radius: 4px;

	&::before {
		content: '';
		position: absolute;
		width: 12px;
		height: 12px;
		top: 50%;
		left: 0px;

		transform: translate(-50%, -50%) rotate(45deg);
		border-top: none;
		border-right: none;

		background-color: inherit;
	}

	@include sm-down {
		margin-top: 8px;
		margin-left: 0;

		&::before {
			top: 0px;
			left: 24px;

			transform: translate(-50%, -50%) rotate(45deg);
			border-bottom: none;
			border-right: none;
		}
	}
}

.sa_vings_tag {
	@include tag1;

	color: #246aff;
}

.sa_vings_tag2 {
	color: #0249a0;
	@include body7;
	margin-top: 0;
	margin-left: auto;
	font-size: 0.6em;
}

.sa_vings_tag3 {
	color: #0249a0;
	border-radius: 63px;
	margin: 0 0 0 auto;
	font-size: 12px;
	font-weight: 400;
	padding: 8px 12px;
	background-color: $ui-mid-grey;
	line-height: 1em;
}

.mattress_upsell {
	h3 {
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		margin-bottom: 10px;
	}

	> p {
		margin-bottom: 10px;
		font-size: 14px;
		line-height: 140%;
	}
}

.mattress_upsell_slider {
	display: block;
	margin: 10px auto;
	position: relative;
	border-radius: 0.5rem;

	:global(.slick-dots) {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		//filter: invert(1);
	}

	picture,
	img {
		height: 100%;
	}
}

.mattress_upsell_check {
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	width: 100%;
	gap: 10px;

	input {
		&:checked {
			& + p {
				box-shadow: #246aff 0 0 0 2px;
			}
		}
	}

	label {
		cursor: pointer;
		height: 100%;
	}
}

.modal_content_container {
	background-color: #fff;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	overflow-y: auto;
	margin: 0 20px;
	line-height: 1.4;
	box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.15);
	border-radius: 10px;
}

.close_button {
	position: absolute;
	top: 30px;
	right: 30px;
	z-index: 10000;
	cursor: pointer;
	border: none;
	background-color: rgb(255 255 255 / 75%);
	border-radius: 100px;
	width: 24px;
	height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		width: 28px;
	}

	@include xs-only {
		top: 15px;
		right: 15px;
	}
}

.mattress_modal {
	background-color: #fff;
	max-width: calc(100vw - 40px);
	max-height: 75vh;

	overflow: hidden auto;

	width: 1400px;
	padding: 60px;
	position: relative;

	z-index: 1000;
	@include xs-only {
		padding: 20px;
		padding-top: 60px;
	}

	display: flex;
	flex-direction: column;

	border-radius: 5px;

	transition: all 0.4s ease;
}

.mattress_upsell_radio_box {
	height: 100%;
	margin: 0;
	background: #ffffff;
	border: none;
	box-shadow: inset rgba(0, 0, 0, 0.1) 0 0 0 1px;
	border-radius: 3px;
	padding: 10px 15px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;

	font-size: 12px;
	line-height: 140%;

	strong {
		font-size: 14px;
		margin-bottom: 0.25em;
	}

	s {
		color: #797c8e;
		margin: 0.5ch 0 0.5ch 0.5ch;
	}
}

.variant_selector_header {
	margin-bottom: 16px;

	display: flex;
	flex-direction: column;
	gap: 6px;
}

.variant_selector_subtitle {
	@include body5;

	color: #999;
}

.color_picker_container {
	margin-bottom: 10px;
}

.color_picker_items {
	margin-top: 16px;

	display: flex;
	align-items: center;
	gap: 16px;
}

.color_picker_item {
	position: relative;
	width: 32px;
	height: 32px;

	border-radius: 50%;
	box-shadow: inset 0px 3px 3px -2px rgba(0, 0, 0, 0.2), inset 0px 2px 2px 0px rgba(0, 0, 0, 0.14), inset 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

	& input {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		padding: 0;
		margin: 0;

		appearance: none;
		outline: none;

		cursor: pointer;
	}
}

.selected {
	&::before {
		content: '';
		position: absolute;
		top: -5px;
		left: -5px;
		width: 42px;
		height: 42px;

		border: solid 2px #246aff;
		border-radius: 50%;
	}
}

.base_checkbox_container {
	display: flex;
	gap: 1rem;

	label {
		transform: translateY(0.1rem);
	}

	cursor: pointer;
}
