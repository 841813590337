@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	background-color: $white;
	color: $black;

	@include section-padding;
}

.wrapper {
	max-width: $max-width;
	margin-inline: auto;
}

.container header {
	h2 {
		text-align: center;
	}
}

.media_container {
	margin: 0;

	list-style: none;
	background: #f2f2f2;
	border-radius: 1rem;

	position: relative;
	height: 30rem;

	overflow: hidden;

	@include sm-down {
		margin: 0;
		height: 24rem;
	}

	> li {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		transition: all 0.5s ease;
	}
}

.tabs {
	list-style: none;
	display: flex;
	align-items: center;

	position: relative;

	margin: 4.5rem 0;

	&::after {
		content: '';
		width: 100%;
		height: 2px;
		background: #dddcdb;
		position: absolute;
		bottom: 0;
		left: 0;

		@include sm-down {
			height: 1px;
		}
	}

	gap: 0;

	overflow: auto visible;
	margin-inline: auto;

	> li {
		flex: 1;
	}

	@include sm-down {
		margin: 2rem 0;
	}
}

.tab.tab {
	@include headline7;
	white-space: nowrap;
	position: relative;
	width: 100%;
	display: block;

	padding: 1rem;

	&::after {
		content: '';
		width: 100%;
		height: 2px;
		background: transparent;
		position: absolute;
		bottom: 0;
		left: 0;

		z-index: 1;

		transition: all 0.25s ease;

		@include sm-down {
			height: 1px;
		}
	}

	&:hover,
	&:focus-visible {
		&::after {
			background: rgba(0, 0, 0, 0.5);
		}
	}

	@include sm-down {
		@include body2;
		padding-bottom: 1rem !important;
	}
}

.tab_selected.tab_selected {
	&::after {
		background: black;
	}
}

.content_container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 4rem;

	@include sm-down {
		grid-template-columns: 1fr;
		gap: 2rem;
	}
}

.lists {
	margin-inline: auto;

	list-style: none;

	position: relative;
	display: grid;
	grid-template-areas: 'center';
	align-items: center;

	margin-top: 2rem;
	margin-bottom: 2rem;

	> * {
		grid-area: center;
	}

	@include sm-down {
		margin-top: 0;
		margin-bottom: 0;
	}
}

.list_wrapper {
	opacity: 0;

	display: grid;
	gap: 0;
	column-gap: 2rem;
	grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));

	@include sm-down {
		grid-template-columns: 1fr;
		padding: 0 1rem;
	}

	> h5 {
		margin-bottom: 2rem;
	}
}

.list {
	margin-bottom: 2rem;
	opacity: 0.3;
	transition: opacity 0.25s ease-in-out;

	&:last-child {
		margin-bottom: 0;
	}

	&.showing {
		opacity: 1;
	}

	h6 {
		margin-bottom: 0.8rem;
	}
}

.inner_list {
	list-style-position: inside;
	list-style-type: square;
	margin-left: 0.5rem;

	margin-bottom: 0;

	display: flex;
	flex-direction: column;

	gap: 0.25rem;

	li {
		display: grid;
		grid-template-columns: auto 1fr;
		align-items: center;
		gap: 1rem;

		&::before {
			content: '';
			width: 0.25em;
			height: 0.25em;
			background: black;
		}
	}
}

.media_list {
	display: flex;
	height: 100%;
	width: 100%;
	overflow: hidden;

	list-style: none;
	margin: 0;
	padding: 0;

	position: relative;
}

.media_item {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	opacity: 0;
	transition: all 0.5s ease;
}

.media_caption {
	text-align: center;
	position: absolute;
	z-index: 2;
	bottom: 3rem;

	@include body4;

	width: 100%;
	padding: 0 1rem;

	opacity: 0.5;

	@include sm-down {
		@include body5;
	}
}

.media_img {
	display: flex;
	align-items: center;
	justify-content: center;

	//padding: 2rem;
	height: 100%;
	width: 100%;
}

.media_video {
	display: flex;
	align-items: center;
	justify-content: center;

	//padding: 2rem;
	height: 100%;
	width: 100%;
}

.button_next.button_next,
.button_prev.button_prev {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);

	z-index: 1;

	background: white;

	border-radius: $br-circle;

	width: 2.5rem;
	height: 2.5rem;

	filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.14)) drop-shadow(0px 11px 11px rgba(0, 0, 0, 0.12)) drop-shadow(0px 25px 15px rgba(0, 0, 0, 0.07)) drop-shadow(0px 45px 18px rgba(0, 0, 0, 0.02))
		drop-shadow(0px 70px 20px rgba(0, 0, 0, 0));

	margin: 0 2rem;
}

.button_next.button_next {
	right: 0;
}

.dots {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;

	gap: 0.5rem;
	justify-content: center;
	margin-bottom: 2rem;
	transition: all 0.5s ease;

	z-index: 1;

	list-style: none;
}

.dot {
	width: 0.5rem;
	height: 0.5rem;

	transition: all 0.5s ease;
}

.dot button {
	background: black;
	border-radius: $br-circle;
	width: 100%;
	height: 100%;
	opacity: 0.7;
}
