@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.navigation {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 1rem;

	.icon {
		width: 1.5rem;
		margin: auto 0;
	}
}

.container {
	background-color: $black;
	color: #a0a0a0;
	padding: 1rem 1rem 6rem;

	min-height: 100svh;

	h3,
	h4 {
		@include body4;
		color: $white;
	}

	h2 {
		@include headline7;
		color: $white;
		text-align: center;
	}
}

.orderItem {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 1.5rem;

	align-items: center;
}

.orderItems {
	display: flex;
	flex-direction: column;
	padding: 1rem 2rem;

	gap: 1rem;

	margin-top: 1rem;
}

.order_description {
	color: #a0a0a0;
	@include body4;
}

.subheadline {
	color: #a0a0a0;
	@include body4;

	text-align: center;
}

.content {
	display: flex;
	flex-direction: column;
	gap: 1rem;

	max-width: 90vw;
	margin-inline: auto;
	justify-content: center;
	align-items: center;

	h2 {
		max-width: 20ch;
	}
}

.done_button {
	position: fixed;
	left: 1.5rem;
	right: 1.5rem;
	bottom: 1.5rem;
}
