@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';

.container {
	background-color: var(--bg-color, $white);
	color: var(--text-color, $black);

	padding: 7rem 4rem;

	@include sm-down {
		padding: 4rem 1.25rem;
	}
}

.container header {
	max-width: 65rem;

	margin-inline: auto;

	text-align: center;

	margin-bottom: 2.5rem;
}

.ctas {
	margin-top: 2.5rem;
	display: flex;
	gap: 1.25rem;
	justify-content: center;

	.learn_more {
		color: var(--learn-more-color);

		svg {
			path {
				stroke: var(--learn-more-color);
			}
		}
	}
}

.header {
	max-width: 30ch;
	margin-inline: auto;
}

.subheader {
	color: #a0a0a0;
}

.details_list {
	display: grid;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	gap: 2rem;

	@include sm-down {
		grid-template-columns: 1fr;
		justify-items: center;
		gap: 1rem;
	}

	margin: 0;
	padding: 0;

	margin-inline: auto;

	max-width: 85rem;

	list-style: none;
}

.details_list li {
	border-radius: 0.875rem;
	border: 1px solid #e2e2e2;
	background: white;
	box-shadow: 0 18px 64px 0 rgba(0, 0, 0, 0.04);

	position: relative;

	text-align: center;

	width: 100%;

	padding: 4rem 2.5rem;

	max-width: 30rem;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	@include sm-down {
		padding: 1.5rem 2rem;
	}
}

.details_list_dark li {
	border: none;
	background: rgba(255, 255, 255, 0.05);
	box-shadow: hsla(0, 0%, 100%, 0.3) 1px 0 1px 0 inset, hsla(0, 0%, 100%, 0.3) -1px 0 1px 0 inset, hsla(0, 0%, 100%, 0.1) 0 -1px 1px inset, hsl(0, 0%, 100%) 0 1px 0.5px inset;
}

.details_list li h4 {
	max-width: 17ch;
	margin-inline: auto;
}

.details_list li p {
	color: #a0a0a0;

	max-width: 30ch;
	margin-inline: auto;
}

.details_list li svg {
	vertical-align: text-top !important;
	height: 1.3em !important;
	width: 4.3em !important;
}
