@import 'styles/mixins/media-queries';
@import 'styles/colors-2023';
@import 'styles/layout-tokens';
@import 'styles/mixins/fonts-2023';
@import 'styles/mixins/button-styles-2023';

.carousel {
	:global(.slick-slide) {
		max-height: 80vh;
		aspect-ratio: 5 / 3;

		> div {
			width: 100%;
			height: 100%;
		}
	}
}

.carousel_dots {
	margin: 0;
	display: flex !important;
	position: absolute;
	justify-content: center;
	align-items: center;

	bottom: 1.5rem;
	left: 50%;
	transform: translateX(-50%);

	list-style: none;
	gap: 0.25rem;

	li {
		width: 0.5rem;
		height: 0.5rem;
		border-radius: 50rem;
		overflow: hidden;
		cursor: pointer;
		position: relative;

		button {
			position: absolute;
			inset: 0;
			padding: 0;
			margin: 0;
			background: none;
			border: none;
			font-size: 0;
			cursor: pointer;

			&:after {
				content: '';
				position: absolute;
				inset: 0.0625rem;
				background-color: var(--dot-color, $neutral-black);
				border-radius: 50rem;

				transition: background-color 0.25s ease-in-out;
			}
		}
	}
}

.image_stack {
	height: 100%;
	display: grid;
	isolation: isolate;
	grid-template-areas: 'center';
	background: linear-gradient(180deg, #c5c5c5 0%, #eaeceb 100%);

	> * {
		grid-area: center;

		transition: opacity 0.5s ease-in-out;
	}
}

.carousel_photo {
	display: block;
	width: 100%;
	height: 100%;

	img {
		display: block;
		width: 100%;
		height: 100%;
	}
}

.carousel_video {
	object-fit: cover;
	width: 100%;
	height: 100%;
}

.arrow {
	width: 2rem;
	height: 2rem;
	position: absolute;
	pointer-events: auto;
	z-index: 5;

	top: 50%;
	transform: translateY(-50%);
	right: 1.25rem;

	&:first-child {
		right: auto;
		left: 1.25rem;
	}

	border-radius: 50rem;
	background: $neutral-white;
	box-shadow: 0 0 22.5px 0 rgba(0, 0, 0, 0.15);

	border: none;
	padding: 0.5rem;
	margin: 0;

	cursor: pointer;
}

.slider_outer {
	position: relative;
	overflow: hidden;
	aspect-ratio: 5/3;
	max-height: 80vh;
	margin-left: auto;
	margin-right: auto;

	border-radius: 6px;
}

.slider_inner {
	margin: 0;
	padding: 0;
	list-style: none;

	max-height: 80vh;
	aspect-ratio: 5/3;
}

.item {
	max-height: 80vh;
	aspect-ratio: 5/3;
	position: absolute;
	left: 0;
	right: 0;

	transition: all 0.4s cubic-bezier(0.45, 0, 0.55, 1);
}

.video_wrapper {
	height: 100%;
	width: 100%;
	padding: 0;
	margin: 0;
	position: relative;

	video {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	button {
		position: absolute;
		inset: 0;
		border: none;
		background: transparent;
		padding: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		z-index: 2;

		img {
			width: 100px !important;

			@include xs-only {
				width: 50px !important;
				margin-top: -10px;
			}
		}
	}
}
