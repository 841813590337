@import 'styles/constants';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.accessory_card {
	padding: 40px;

	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 16px;

	background-color: #f2f2f2;
	border-radius: 24px;

	cursor: pointer;

	&:link,
	&:visited {
		color: #000;
		text-decoration: none;
	}

	&:hover,
	&:active {
		color: #000;
		text-decoration: none;
	}

	img {
		mix-blend-mode: darken;
		aspect-ratio: 7 / 4;
	}

	@include xs-only {
		padding: 24px;
		gap: 32px;
		border-radius: 8px;
	}
}

.accessory_card_content {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 8px;
}

.accessory_card_title {
	@include headline7;
	text-align: center;

	@include xs-only {
		@include headline10;
	}
}

.accessory_card_subtitle {
	@include body3;
	text-align: center;

	@include xs-only {
		display: none;
	}
}

.accessory_card_price_container {
	display: flex;
	gap: 10px;

	align-items: center;

	@include xs-only {
		flex-wrap: wrap;
		justify-content: center;
	}
}

.accessory_card_new_price {
	@include headline8;

	@include xs-only {
		@include headline10;
	}
}

.accessory_card_old_price {
	@include headline8;

	color: #999;
	text-decoration-line: line-through;

	@include xs-only {
		@include headline10;
	}
}

.accessory_card_price_savings_container {
	padding: 5px 10px;

	display: flex;
	justify-content: center;
	align-items: center;

	background-color: #f1d6d2;
	border-radius: 4px;
}

.accessory_card_price_savings {
	@include tag2;

	color: #ba321d;

	@include xs-only {
		@include tag4;
	}
}

.color_picker_items {
	display: flex;
	align-items: center;
	gap: 10px;
}

.color_picker_item {
	width: 20px;
	height: 20px;

	border-radius: 50%;
	box-shadow: inset 0px 3px 3px -2px rgba(0, 0, 0, 0.2), inset 0px 2px 2px 0px rgba(0, 0, 0, 0.14), inset 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.color_picker_item_light {
	background-color: #f6f6f6;
}

.color_picker_item_dark {
	background-color: #b8b8b8;
}
