@import 'styles/constants';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts_legacy';
@import 'styles/mixins/fonts-2024';

.headline {
	@include headline3;

	margin-bottom: 90px;

	color: #000;
	text-align: center;

	@include xs-only {
		@include headline6;

		padding-top: 70px;
		// This eliminates the weird 1px horizontal line that is sometimes visible on mobile
		margin-top: -1px;
		margin-bottom: 48px;
	}
}

.items {
	display: grid;
	grid-gap: 30px;
	grid-template-columns: repeat(3, 1fr);
	justify-content: center;
	color: white;

	&.items2 {
		grid-template-columns: repeat(4, 1fr);

		@include xs-only {
			grid-template-columns: 1fr;
		}
	}

	@include xs-only {
		grid-template-rows: min-content min-content min-content;
		grid-template-columns: 1fr;
	}
}

.item {
	background-color: #f7f8fa;
	display: flex;
	transform: scale(1);
	transition: 200ms ease-in-out transform;

	&:hover {
		transform: scale(1.1);
	}
}

.link {
	padding: 30px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;

	&:hover {
		text-decoration: none;
	}

	> p {
		margin-top: 0;
		margin-bottom: auto;
		font-weight: 700;
		font-size: 24px;
		color: #000;

		@include sm-only {
			font-size: 18px;
		}
	}

	> div {
		margin-top: 10px;
		font-weight: 400;
		font-size: 18px;
		color: #000;

		> strong {
			color: $brand-secondary;
			text-transform: uppercase;
			margin-left: 5px;
		}
	}
}
