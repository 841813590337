@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';

.wrapper {
	&[data-theme='dark'] {
		background: #0d0d0d;
		color: $text-white;

		.breakdown_item_content::after {
			background: $border-white;
		}

		.breakdown_item_description {
			color: $text-grey-light;
		}

		li[data-highlighted='true'] .dot_inner {
			background: $text-white;
		}

		li[data-highlighted='false'] .dot_inner {
			background: $text-grey-light;
		}
	}

	&[data-theme='light'] {
		background: $background-off-white;
		color: $text-black;

		.breakdown_item_content::after {
			background: $border-grey-dark;
		}

		.breakdown_item_description {
			color: $text-grey-dark;
		}

		.media_container {
			background: $background-white;
		}

		li[data-highlighted='true'] .dot_inner {
			background: $text-black;
		}

		li[data-highlighted='false'] .dot_inner {
			background: $text-grey-dark;
		}
	}

	@include section-padding;
}

.container {
	max-width: 90rem;
	margin-inline: auto;

	height: 100%;

	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto 1fr;
	row-gap: 3.75rem;
	column-gap: 5rem;

	@include sm-down {
		grid-template-columns: 1fr;
		grid-template-rows: auto;

		gap: 1rem;
	}
}

.container header {
	grid-column: 2;
	grid-row: 1;

	@include sm-down {
		grid-column: unset;
		grid-row: 1;
	}
}

.container h2 {
	max-width: 18ch;

	margin-bottom: 1.5rem;

	@include sm-down {
		max-width: 20ch;
		margin-bottom: 1rem;
	}
}

.breakdown {
	grid-column: 2;
	grid-row: 2;

	list-style: none;
	padding: 0;
	margin: 0;

	@include sm-down {
		grid-column: unset;
		grid-row: 3;
	}
}

.breakdown_list {
	display: flex;
	flex-direction: column;

	padding: 0;
	margin: 0;

	list-style: none;

	min-height: var(--min-height, 0px);
}

.breakdown_item {
	opacity: 0.7;

	button {
		text-align: left;
	}

	&[data-highlighted='true'] {
		opacity: 1;

		.breakdown_item_content {
			&::after {
				opacity: 1;
			}
		}
	}
}

.breakdown_item_content {
	display: flex;
	flex-direction: column;

	position: relative;

	padding: 1.75rem 0 1.75rem 2rem;

	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 2px;

		opacity: 0.3;

		background: white;
		transition: all 0.2s ease-out;
	}

	transition: all 0.2s ease-out;
}

.breakdown_item h3 {
	transition: all 0.2s ease-out;
}

.breakdown_item p {
	overflow: hidden;

	display: block;

	transition: all 0.2s ease-out;
}

.media_container {
	grid-column: 1;
	grid-row: 1 / 3;

	position: relative;

	border-radius: 0.5rem;
	contain: paint;

	@include sm-down {
		grid-column: unset;
		grid-row: 2;

		min-height: 18rem;
	}
}

.media_stack {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	pointer-events: none;

	&[data-highlighted='true'] {
		opacity: 1;
		pointer-events: auto;
	}

	transition: all 0.2s ease-out;
}

.media {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	list-style: none;
	padding: 0;
	margin: 0;
}

.media_control_prev,
.media_control_next {
	position: absolute;

	z-index: 1;
	top: 50%;
	transform: translateY(-50%);
}

.media_control_prev {
	left: 0.5rem;
}

.media_control_next {
	right: 0.5rem;
}

.dots {
	position: absolute;
	bottom: 2rem;
	left: 0;
	width: 100%;

	display: flex;
	justify-content: center;

	list-style: none;
	padding: 0;
	margin: 0;
	align-items: center;

	button {
		padding: 0.25rem !important;
	}

	li[data-highlighted='true'] .dot_inner {
		background: $white;
	}

	@include sm-down {
		bottom: 1rem;
	}
}

.dot_inner {
	border-radius: $br-circle;

	width: 0.5rem;
	height: 0.5rem;

	transition: all 0.2s ease-out;
}

.media_item {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	opacity: 0;
	visibility: hidden;
	pointer-events: none;

	&[data-highlighted='true'] {
		opacity: 1;
		visibility: visible;
		pointer-events: auto;
	}

	transition: all 0.2s ease-out;

	picture,
	video,
	img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: contain;
	}

	padding: 2.5rem 1rem;

	@include sm-down {
		padding: 0 1rem;
	}
}
