@import 'styles/colors-2023';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2023';
@import 'styles/mixins/fonts-2023';

.container {
	position: fixed;
	z-index: 20;

	background: rgba(0, 0, 0, 0.6);
	color: $neutral-white;
	box-shadow: #3f3f3f80 1px 1px 0 1px;
	left: 0;
	right: 0;

	backdrop-filter: blur(4px);

	padding: 1rem 5rem;
	display: flex;
	align-items: center;

	@include sm-down {
		display: none;
	}

	h2 {
		@include subheading3;
		flex: 1;
	}
}

.promo_text {
	@include body7;
	color: $neutral-white;
	margin: 0 2rem;
}

.size_text {
	@include body7;
}

.divider {
	height: 1.5rem;
	width: 1px;
	background: $neutral-5;
	margin: 0 0.75rem;
}

.price {
	@include subheading5;
}

.strike_price {
	@include subheading5;
	font-weight: normal;
	color: $neutral-white;
	opacity: 0.7;
	margin-left: 0.5ch;
}

.cta {
	margin-left: 2rem;
}
